<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Left Text-->
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img fluid :src="imgUrl" alt="Login V2" />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center px-2 p-lg-5"
        style="position: releative"
      >
        <div class="auth-bg"></div>
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title title-tag="h2" class="font-weight-bold mb-1">
            Добро пожаловать!
          </b-card-title>
          <b-card-text class="mb-2">
            Пожалуйста, войдите в свой аккаунт
          </b-card-text>

          <!-- form -->
          <validation-observer ref="loginValidation">
            <b-form class="auth-login-form mt-2" @submit.prevent>
              <!-- email -->
              <b-form-group label="Логин" label-for="login-email">
                <validation-provider
                  #default="{ errors }"
                  name="Логин"
                  rules="required"
                >
                  <b-form-input
                    id="login-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false : null"
                    name="login-email"
                    placeholder="Введите логин"
                  />
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Пароль</label>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Пароль"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                </validation-provider>
              </b-form-group>

              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
                @click="validationForm"
              >
                Войти
              </b-button>
            </b-form>
          </validation-observer>
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import roleAbilities from "@/libs/acl/roleAbilities"
import navigationLinks from "@/navigation/navigationLinks"
import { ValidationProvider, ValidationObserver } from "vee-validate"
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
} from "bootstrap-vue"
import { required, email } from "@validations"
import { togglePasswordVisibility } from "@core/mixins/ui/forms"
import store from "@/store/index"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      notifShowed: false,
      status: "",
      password: "",
      userEmail: "",
      sideImg: require("@/assets/images/pages/login-v2.svg"),
      // validation rulesimport store from '@/store/index'
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon"
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/login-v2-dark.svg")
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    validationForm() {
      this.$refs.loginValidation.validate().then((success) => {
        if (success) {
          this.$store
            .dispatch("info/login", {
              username: this.userEmail,
              password: this.password,
            })
            .then(() => {
              // let userData = JSON.parse(localStorage.getItem('userData'));
              let userData = JSON.parse(localStorage.getItem("user"))
              this.$ability.update(roleAbilities[userData.role])
              navigationLinks.forEach((link) => {
                if (
                  this.$ability.can("read", link.subject) &&
                  !this.notifShowed
                ) {
                  this.notifShowed = true
                  this.$router.push({ name: link.route }).then(() => {
                    this.$toast({
                      component: ToastificationContent,
                      position: "top-right",
                      props: {
                        title: `Здравствуйте ${
                          userData.last_name + " " + userData.first_name
                        }`,
                        icon: "CoffeeIcon",
                        variant: "success",
                        text: `Вы успешно вошли в систему!`,
                      },
                    })
                  })
                }
              })
            })
            .catch((err) => {
              console.log(err)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Неправильный логин или пароль",
                  icon: "EditIcon",
                  variant: "danger",
                },
              })
            })
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Заполните все поля",
              icon: "EditIcon",
              variant: "danger",
            },
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
.auth-bg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.1;
  background: url("~@/assets/images/2.jpg");
}
</style>
