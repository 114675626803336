export default [
  {
    title: 'Ведомость посещения',
    route: 'visit',
    subject: 'visit',
  },
  {
    title: 'Клубы',
    route: 'visit-common',
    subject: 'commonVisit',
  },
  {
    title: 'Занятия',
    route: 'visit-facultative',
    subject: 'facultativeVisit',
  },
  // {
  //   title: 'Список детей',
  //   route: 'home',
  //   subject: 'child',
  // },
  // {
  //   title: 'Сотрудники',
  //   route: 'workers',
  //   subject: 'employee',
  // },
  {
    title: 'Группы',
    route: 'groups',
    subject: 'group',
  },
  {
    title: 'Архив',
    route: 'archive',
    subject: 'archive',
  },
  // {
  //   title: 'Секции',
  //   route: 'sections',
  //   subject: 'section',
  // },
  // {
  //   title: 'График работы',
  //   route: 'worker-schedulers',
  //   subject: 'schedule',
  // },
  {
    title: 'Комментарии',
    route: 'reviews',
    subject: 'reviews',
  },
]
